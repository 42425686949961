import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`As-salamu alaykum!`}</h2>
    <p>{`I'm Mishka and I'm building thiscodeWorks - the Pinterest of Code. A place to save and organize code snippets that work.`}</p>
    <p>{`This is where I spend most of my day:
`}<a parentName="p" {...{
        "href": "https://www.thiscodeworks.com"
      }}>{`https://www.thiscodeworks.com`}</a></p>
    <p>{`Physically, I'm in Peshawar, Pakistan.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      